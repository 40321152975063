<template>
    <div class="app-container">
        <el-row :gutter="12">
            <el-col :span="24">
                <el-card class="box-card">
                    <div slot="header" class="card-header">
                        <span class="card-title">{{ $t('comm.serviceone.iden-date') }}</span>

                        <span class="card-buttons">
                            <router-link :to="{ path: '/comunicare/service' }">
                                <el-button type="danger" icon="el-icon-back" size="mini" plain>
                                    {{ $t('general.back') }}
                                </el-button>
                            </router-link>
                        </span>
                    </div>

                    <ul class="info-list">
                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="info" />
                                {{ $t('comm.serviceone.unique-id') }}
                            </span>
                            <span class="float-right">
                                {{ this.sid }}
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="user" />
                                {{ $t('comm.serviceone.name') }}
                            </span>
                            <span class="float-right">
                                {{ nume }}
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="cart" />
                                {{ $t('comm.serviceone.order-nr') }}
                            </span>
                            <span class="float-right">
                                <el-link type="primary">
                                    <router-link
                                        :to="{ path: `/vanzari/comanda/${nrcomanda}` }"
                                        type="primary"
                                        target="_blank"
                                    >
                                        {{ nrcomanda }}
                                    </router-link>
                                </el-link>
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="information-outline" />
                                {{ $t('comm.serviceone.product-model') }}
                            </span>
                            <span class="float-right">
                                {{ products_model }}
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="list" />
                                {{ $t('comm.serviceone.product-name') }}
                            </span>
                            <span class="float-right">
                                <el-link :href="products_url" type="primary" target="_blank">{{
                                    products_name
                                }}</el-link>
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="file-text" />
                                {{ $t('comm.serviceone.bill-nr') }}
                            </span>
                            <span class="float-right">
                                {{ nrfactura }}
                            </span>
                        </li>
                    </ul>
                </el-card>
            </el-col>

            <el-col :span="24">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('comm.serviceone.conv') }}</span>
                    </div>
                    <div>
                        <Conversation :messages="messages" :answer="!resolved" :upload="true" @send="sendMessage" />
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import communicationStore from '@/store/modules/communication';
import { get, call, sync } from 'vuex-pathify';
import { setParams } from '@/utils/index.js';

export default {
    props: ['sid'],
    components: {
        Conversation: () => import('@/components/Conversation/index.vue')
    },
    computed: {
        ...sync('communication/service@single', {
            id: 'id',
            nume: 'nume',
            products_model: 'products_model',
            products_name: 'products_name',
            nrcomanda: 'nrcomanda',
            nrfactura: 'nrfactura',
            products_url: 'products_url',
            resolved: 'resolved',
            data: 'data',
            chat: 'chat'
        }),
        messages() {
            return this.chat.map((row) => {
                return {
                    message: row.raspuns,
                    fromClient: row.tip_discutie,
                    name: this.nume,
                    date: row.datar
                };
            });
        }
    },
    methods: {
        GetServiceRequest: call('communication/GetServiceRequest'),
        AnswerServiceRequests: call('communication/AnswerServiceRequests'),
        getServiceRequest() {
            this.GetServiceRequest({
                uniqueID: this.sid
            }).catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getServiceRequest',
                        params: {
                            uniqueID: this.sid
                        }
                    }
                });
            });
        },
        sendMessage(message, files) {
            let params = {
                id: this.sid,
                answer: message
            };
            params = setParams(params);

            if (files.length > 0) {
                for (const i in files) {
                    params.append('file_' + i, files[i]);
                }
            }

            this.AnswerServiceRequests(params)
                .then((response) => {
                    if (response.message.success) {
                        // Reload data
                        this.getServiceRequest();
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'answerServiceRequests',
                            params
                        }
                    });
                });
        }
    },
    mounted() {
        this.getServiceRequest();
    },
    beforeCreate() {
        this.$store.registerModule('communication', communicationStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('communication');
    }
};
</script>

<style lang="scss" scoped>
.info-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
}

.info-list > li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 0;
}

.info-list > li + li {
    border-top: 1px #e8e8e8 solid;
}

.info-list > li:hover {
    background: #f9f9f9;
}

.info-list .list-title {
    display: inline-flex;
    align-items: center;
    color: #5f5f5f;
    min-width: 165px;
}

.info-list .list-title > i {
    color: #fb7621;
    width: 45px;
    text-align: center;
    font-size: 16px;
}

.info-list > li > span.float-right {
    text-align: right;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header .card-buttons {
    text-align: right;
}
</style>
